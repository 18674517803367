import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // TrustedForm
    var formId = '#school_match_form';

    var tf = document.createElement('script');
    tf.type = 'text/javascript';
    tf.async = true;
    tf.src = ("https:" == document.location.protocol ? 'https' : 'http') +
      '://api.trustedform.com/trustedform.js?form_selector='+escape(formId)+'&field=match[consent_certificate_url]&ping_field=xxTrustedFormPingUrl&use_tagged_consent=true&l=' +
      new Date().getTime() + Math.random();
    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tf, s);

    // End TrustedForm
  }
}
